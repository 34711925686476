module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.oneplanetassociates.com/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"dangerouslySetAllPagesToNoIndex":false,"dangerouslySetAllPagesToNoFollow":false,"title":"One Planet Associates Ltd","description":"OPA provides planning and consultancy services for domestic and commercial clients.","openGraph":{"type":"website","locale":"en_GB","url":"https://www.oneplanetassociates.com/","site_name":"One Planet Associates","images":[{"url":"./src/images/Bigger-tighter-OPA.png","alt":"One Planet Associates logo"}]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Poppins:300,400,600","Merriweather:300"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"One Planet Associates","short_name":"OPA","start_url":"/","background_color":"#f1ede5","theme_color":"#475451","display":"minimal-ui","icon":"src/images/OPA.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"96e6006d9b461b6f61368ebafdc5ef13"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
